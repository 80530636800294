import React from "react"
import Layout from "../components/layout"
import Entrada from "../images/hommerfitness-entrada.jpeg"

export default () => (
  <Layout title="Sobre">
    <div className="global-inner">
      <div className="row">
        <div className="col s12">
          <img
            className="responsive-img"
            src={Entrada}
            alt="A HommerFitness"
            width="100%"
          />
        </div>

        <div className="col s12">
          <p>
            Nós da Hommer trabalhamos com o propósito de trazer ao nosso público
            o melhor do mundo fitness, aliado a ambientes agradáveis e ao
            serviço de profissionais altamente qualificados.
          </p>
          <p>
            Se você ainda não conhece a Hommer Fitness, venha agora conhecer a
            unidade e junte se aos associados que vestem nossa camisa.
          </p>

          <ul className="ul-with-list">
            <li>Há mais de 13 anos no mercado;</li>
            <li>Total de 15 aparelhos ergométricos;</li>
            <li>Aberto de Domingos e Feriados;</li>
            <li>2 professores por sala em horários de pico;</li>
            <li>Estrutura de 2 andares;</li>
            <li>Estacionamento no local;</li>
            <li>Novos aparelhos de musculação.</li>
          </ul>

          <p>Serviços e comodidades para alunos:</p>

          <ul className="ul-with-list">
            <li>Nutricionista</li>
            <li>Avaliação Física</li>
            <li>Personal Trainer</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)
